<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Busque pelo histórico de fechamento de seus caixas.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md-6 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" :clearable="false" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
						<div class="col-9 col-md-5 mb-3">
							<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
							<datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
						</div>
						<div class="col-3 col-md-1 px-0 mb-3 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarFechamentos"><i class="fas fa-search font-13"></i></button>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<fechamento v-for="(fechamento, index) in pesquisa.retorno.resultado" :key="index" :fechamento="fechamento" :index="index" @visualizar="buscarFechamento($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalFechamento -->
      <div class="modal fade" id="modalFechamento" tabindex="-1" aria-labelledby="modalFechamentoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalFechamentoLabel">Fechamento - {{ modal.dataFim == null ? 'Sem data' : modal.dataFim }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="row">
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-calendar color-theme font-10 me-1"></i> Data inicio</h1>
										<p class="font-13 mb-0 text-secondary text-end"><span class="limitador-1">{{ modal.dataInicio == null ? 'Sem data' : modal.dataInicio }}</span></p>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-calendar color-theme font-10 me-1"></i> Data fim</h1>
										<p class="font-13 mb-0 text-secondary text-end"><span class="limitador-1">{{ modal.dataFim == null ? 'Sem data' : modal.dataFim }}</span></p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-store color-theme font-10 me-1"></i> Loja</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">
												<span> {{ modal.nomeLoja == null ? 'Sem loja' : modal.nomeLoja }}</span>
												<span v-if="modal.numeroLoja"><small class="mx-1"> | </small><span><i class="far fa-tag font-9 me-1"></i> {{ modal.numeroLoja }}</span></span>
											</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-desktop color-theme font-10 me-1"></i> Caixa</h1>
										<p class="font-13 mb-0 text-secondary text-end"><span class="limitador-1">{{ modal.nomePdv == null ? 'Sem caixa' : modal.nomePdv }}</span></p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-user color-theme font-10 me-1"></i> Operador(a)</h1>
										<p class="font-13 mb-0 text-secondary text-end"><span class="limitador-1">{{ modal.operador == null ? 'Sem operador(a)' : modal.operador }}</span></p>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="card shadow-sm">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-user color-theme font-10 me-1"></i> Fiscal</h1>
										<p class="font-13 mb-0 text-secondary text-end"><span class="limitador-1">{{ modal.fiscal == null ? 'Sem fiscal' : modal.fiscal }}</span></p>
									</div>
								</div>
							</div>
						</div>
						<div class="row text-center">
							<div class="col-4 col-md-2 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body px-1 py-2">
										<img src="@/assets/images/pagamentos/dinheiro.png" height="35" @error="imageError" />
										<h5 class="font-13 mb-0 mt-2">
											<span class="limitador-1"><small>R$ </small>{{ modal.totalVendasDinheiro == null ? '0,00' : parseFloat(modal.totalVendasDinheiro).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</h5>
									</div>
								</div>
							</div>
							<div class="col-4 col-md-2 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body px-1 py-2">
										<img src="@/assets/images/pagamentos/debito.png" height="35" @error="imageError" />
										<h5 class="font-13 mb-0 mt-2">
											<span class="limitador-1"><small>R$ </small>{{ modal.totalVendasDebito == null ? '0,00' : parseFloat(modal.totalVendasDebito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</h5>
									</div>
								</div>
							</div>
							<div class="col-4 col-md-2 pe-md-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body px-1 py-2">
										<img src="@/assets/images/pagamentos/credito.png" height="35" @error="imageError" />
										<h5 class="font-13 mb-0 mt-2">
											<span class="limitador-1"><small>R$ </small>{{ modal.totalVendasCredito == null ? '0,00' : parseFloat(modal.totalVendasCredito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</h5>
									</div>
								</div>
							</div>
							<div class="col-4 col-md-2 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body px-1 py-2">
										<img src="@/assets/images/pagamentos/pix.png" height="35" @error="imageError" class="p-1" />
										<h5 class="font-13 mb-0 mt-2">
											<span class="limitador-1"><small>R$ </small>{{ modal.totalVendasPix == null ? '0,00' : parseFloat(modal.totalVendasPix).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</h5>
									</div>
								</div>
							</div>
							<div class="col-4 col-md-2 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body px-1 py-2">
										<img src="@/assets/images/pagamentos/convenio.png" height="35" @error="imageError" />
										<h5 class="font-13 mb-0 mt-2">
											<span class="limitador-1"><small>R$ </small>{{ modal.totalVendasConvenio == null ? '0,00' : parseFloat(modal.totalVendasConvenio).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</h5>
									</div>
								</div>
							</div>
							<div class="col-4 col-md-2">
								<div class="card shadow-sm mb-1">
									<div class="card-body px-1 py-2">
										<img src="@/assets/images/pagamentos/moedas.png" height="35" @error="imageError" />
										<h5 class="font-13 mb-0 mt-2">
											<span class="limitador-1"><small>R$ </small>{{ modal.totalVendasOutrosMeios == null ? '0,00' : parseFloat(modal.totalVendasOutrosMeios).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</h5>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<pagamento class="px-1" v-for="(pagamento, index) in modal.transacoes" :key="index" :pagamento="pagamento" :index="index" :showEdit="false"
								@imprimirConvenio="imprimirConvenio($event)" />
							
							<div class="col-12 text-center" v-if="modal.transacoes.length == 0">
								<div class="card">
									<div class="card-body py-5">
										<i class="fal fa-handshake-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma transação encontrada</h4>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" v-if="modal.nomePdv" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<impressao id="fechamento" class="d-none" :fechamento="modal" v-if="modal.nomePdv" />
		<impressaoConvenio id="convenio" class="d-none" :convenio="comprovantes.convenio" v-if="comprovantes.convenio != null" />
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import pagamento from '@/components/modelos/Pagamento.vue'
import fechamento from '@/components/pdv/fechamentos/Fechamento.vue'
import impressao from '@/components/impressao/ComprovanteFechamento.vue'
import impressaoConvenio from '@/components/impressao/ComprovanteConvenio.vue'

export default {
	name: 'Fechamentos',
	data: function () {
		return {
			pesquisa: {'loja': null, 'data': [new Date(), new Date()], 'retorno': {'idLoja': null, 'numeroLoja': null, 'resultado': []}},
			modal: {
            "id": null, "totalVendasCredito": null, "totalVendasPix": null, "saldoFinal": null, "numeroLoja": null, "dataFim": null, "totalVendasConvenio": null, 
            "totalVendasDinheiro": null, "saldoInicial": null, "totalVendasOutrosMeios": null, "nomePdv": null, "saldoInformado": null, "transacoes": [], "fiscal": null, 
            "totalVendasDebito": null, "nomeLoja": null, "dataInicio": null, "operador": null
         },
			comprovantes: {'convenio': null}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
		fechamento, impressao, pagamento, impressaoConvenio
	},
	methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('fechamento', {styles: ['./bootstrap.print.css']}), 250);
      },
		imprimirConvenio : function (pagamento) {
         this.comprovantes.convenio = {
            'nome': pagamento.nome, 'cpfCnpj': pagamento.cpfCnpj, 'valor': pagamento.valor, 'data': pagamento.data, 
            'nomeLoja': this.modal.nomeLoja, 'numeroLoja': this.modal.numeroLoja, 'nomePdv': this.modal.nomePdv
         }

         setTimeout(() => this.$htmlToPaper('convenio', {styles: ['./bootstrap.print.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Fechamentos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'numeroLoja', 'nomeLoja', 'nomePdv', 'dataInicio', 'dataFim', 'saldoInicial', 'saldoFinal', 'saldoInformado'], 
				'lista': null
			})
		},
		buscarFechamentos : function () {
			if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/searchFechamentos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarFechamento : function (fechamento) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

         $('#modalFechamento').modal('show')

			this.$axios({
				method: 'get',
				url: this.urlRest +'pdv/getFechamento',
				params: {
					id: fechamento.id
				}
			}).then(response => {
				this.modal = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>